import { FC } from 'react';
import { ButtonProps } from 'react-html-props';
import cn from 'classnames';

import styles from './Button.module.scss';

interface IButton extends ButtonProps {
	color?: 'blue' | 'white' | 'red';
	size?: 'medium' | 'small';
}

const Button: FC<IButton> = ({
	color = 'blue',
	size = 'medium',
	children,
	className,
	...restProps
}) => {
	const classNames = cn(styles.button, className, styles[color], {
		[styles.small]: size === 'small',
		[styles.disabled]: restProps.disabled,
	});

	return (
		<button className={classNames} {...restProps}>
			{children}
		</button>
	);
};

export default Button;
