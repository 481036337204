import { TransActionEnum } from 'common/interfaces';
import { baseConfig } from './baseConfig';
import {
	IGetManyUsers,
	ICreateHolding,
	ICreateStrategy,
	IHolding,
	ILoginRequest,
	ISessionResponse,
	IStrategy,
	IUser,
	IAdminUser,
	ICreateOutputRequest,
	ITransaction,
	IOutputRequest,
	IConfirmOutputRequest,
	IAdminUserFull,
	IHoldingStats,
	ISettings,
	IAdminUsersResponse,
	IUpcomingFinish,
	IMainWallet,
	IStatusSettings,
	IReferralAction,
	ISetAdmin,
} from './services.interface';

export const authService = {
	async getSession() {
		const { data } = await baseConfig.post<ISessionResponse>('/auth/session');
		return data;
	},
	async login(req: ILoginRequest) {
		const { data } = await baseConfig.post<ISessionResponse>('/auth/login', req);
		return data;
	},
};

export const userService = {
	async getUser(id: number) {
		const { data } = await baseConfig.post<IUser>(`/users/${id}`);
		return data;
	},

	async getReferralActions() {
		const { data } = await baseConfig.post<IReferralAction[]>(`/users/referrals`);
		return data;
	},

	async getAdmins() {
		const { data } = await baseConfig.post<IAdminUser[]>(`/users/getAdmin`);
		return data;
	},

	async setAdmin(body: ISetAdmin) {
		const { data } = await baseConfig.post(`/users/setAdmin`, body);
		return data;
	},
};

export const strategyService = {
	async getAll() {
		const { data } = await baseConfig.post<IStrategy[]>(`/strategy/getAll`);
		return data;
	},
	async create(body: ICreateStrategy) {
		const { file, ...restBody } = body;

		const { data: strategy } = await baseConfig.post<IStrategy>(`/strategy`, restBody);

		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			const { data } = await baseConfig.post<IStrategy>(
				`/strategy/${strategy.id}/uploadImage`,
				formData
			);

			return data;
		}
		return strategy;
	},
	async delete(id: number) {
		await baseConfig.delete(`/strategy/${id}`);
	},
};

export const holdingService = {
	async create(body: ICreateHolding) {
		const { data } = await baseConfig.post<IHolding>(`/holding`, body);
		return data;
	},
	async getActive() {
		const { data } = await baseConfig.post<IHolding[]>(`/holding/active`);
		return data;
	},
	async getHistory() {
		const { data } = await baseConfig.post<IHolding[]>(`/holding/history`);
		return data;
	},
	async getStats(days: number = 180) {
		const { data } = await baseConfig.post<IHoldingStats>(`/holding/stats`, { days });
		return data;
	},
};

export const transactionService = {
	async getAll(action?: TransActionEnum) {
		const { data } = await baseConfig.post<ITransaction[]>(`/transaction/getByUser`, {
			action,
		});
		return data;
	},
	async getWaitingOutputRequest() {
		const { data } = await baseConfig.post<IOutputRequest | null>(
			`/outputRequest/getByUser`
		);
		return data;
	},
	async cancelWaitingOutputRequest() {
		await baseConfig.post<IOutputRequest | null>(`/outputRequest/cancel`);
	},
	async checkWallet() {
		const { data } = await baseConfig.post<number | null>(`/transaction/check`);
		return data;
	},
	async outputRequest(body: ICreateOutputRequest) {
		const { data } = await baseConfig.post<IOutputRequest | ITransaction>(
			`/outputRequest/output`,
			body
		);
		return data;
	},
};

export const adminService = {
	async getUsers(res: IGetManyUsers) {
		const { data } = await baseConfig.post<IAdminUsersResponse>(`/users/getMany`, res);

		return data;
	},
	async getUser(id: number) {
		const { data } = await baseConfig.post<IAdminUserFull>(`/users/${id}/full`);
		return data;
	},
	async getOutputRequests() {
		const { data } = await baseConfig.post<IOutputRequest[]>(`/outputRequest/getAll`);
		return data;
	},
	async confirmOutputRequests(body: IConfirmOutputRequest) {
		const { data } = await baseConfig.post<IOutputRequest>(
			`/outputRequest/confirm`,
			body
		);
		return data;
	},
	async getUpcomingFinish() {
		const { data } = await baseConfig.post<IUpcomingFinish>(`/holding/upcomingFinish`);

		return data;
	},
	async getWallets() {
		const { data } = await baseConfig.post<IMainWallet[]>(`/main-wallet/getAll`);

		return data;
	},
	async createWallet() {
		await baseConfig.post(`/main-wallet`);
	},
};

export const settingsService = {
	async getSupport() {
		const { data } = await baseConfig.post<string>(`/settings/getSupport`);
		return data;
	},
	async updateSupport(name: string) {
		const { data } = await baseConfig.post<string>(`/settings/updateSupport`, {
			name: 'support-name',
			value: name,
		});
		return data;
	},

	async get(name: string) {
		const { data } = await baseConfig.post<ISettings>(`/settings/get`, { name });
		return data;
	},
	async getAll() {
		const { data } = await baseConfig.post<{
			settings: ISettings<number>[];
			status: IStatusSettings;
		}>(`/settings/getAll`);

		return data;
	},
	async update(name: string, value: string | number) {
		const { data } = await baseConfig.post<ISettings>(`/settings/update`, {
			name,
			value,
		});
		return data;
	},
	async updateStatus(body: IStatusSettings) {
		await baseConfig.post<ISettings>(`/status`, body);
	},
};
