import { FC } from 'react';

import styles from './Status.module.scss';
import ActionPopup from 'components/ActionPopup/ActionPopup';
import { IStatus, StatusType } from 'services/services.interface';
import StatusInfo from './StatusInfo';
import Button from 'components/Button/Button';

interface IStatusPopup {
	show: boolean;
	onClose: () => void;

	statusDays: number;
	maxDays: number;
	percent: number;
	status: StatusType;

	sumToDayUpgrade: number;
	sumToStatusUpgrade: number | null;
	currentSum: number;
	statusMissingDays: number;

	currentSettings: IStatus;
}

const StatusPopup: FC<IStatusPopup> = ({
	currentSum,
	statusDays,
	maxDays,
	percent,
	status,
	statusMissingDays,
	sumToDayUpgrade,
	sumToStatusUpgrade,
	show,
	onClose,
	currentSettings,
}) => {
	return (
		<ActionPopup show={show} onClose={onClose}>
			<div className={styles.popup}>
				<div style={{ maxWidth: '80%' }}>
					<StatusInfo
						statusDays={statusDays}
						maxDays={maxDays}
						percent={percent}
						status={status}
						sumToDayUpgrade={sumToDayUpgrade}
						sumToStatusUpgrade={sumToStatusUpgrade}
						currentSum={currentSum}
						statusMissingDays={statusMissingDays}
					/>
				</div>

				<div className={styles.popup_info}>
					<div className={styles.popup_item}>
						<div className={styles.item_icon}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M16.5 9.5L12.3 13.7L10.7 11.3L7.5 14.5"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M14.5 9.5H16.5V11.5"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						Access to basic strategies
					</div>

					<div className={styles.popup_item}>
						<div className={styles.item_icon}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9 19C9 19.75 8.79 20.46 8.42 21.06C7.73 22.22 6.46 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 16.79 2.79 15 5 15C7.21 15 9 16.79 9 19Z"
									stroke="#949395"
									stroke-width="1.5"
									stroke-miterlimit="10"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M3.44141 18.9995L4.43141 19.9895L6.56141 18.0195"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M17.7514 7.04997C17.5114 7.00997 17.2614 6.99998 17.0014 6.99998H7.00141C6.72141 6.99998 6.45141 7.01998 6.19141 7.05998C6.33141 6.77998 6.53141 6.52001 6.77141 6.28001L10.0214 3.02C11.3914 1.66 13.6114 1.66 14.9814 3.02L16.7314 4.78996C17.3714 5.41996 17.7114 6.21997 17.7514 7.04997Z"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M22 12V17C22 20 20 22 17 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C3.8 15 2.73 15.53 2 16.36V12C2 9.28 3.64 7.38 6.19 7.06C6.45 7.02 6.72 7 7 7H17C17.26 7 17.51 7.00999 17.75 7.04999C20.33 7.34999 22 9.26 22 12Z"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M22 12.5H19C17.9 12.5 17 13.4 17 14.5C17 15.6 17.9 16.5 19 16.5H22"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						Limit $ {currentSettings.holdingsLimit}
					</div>

					<div className={styles.popup_item}>
						<div className={styles.item_icon}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M22 6V8.42C22 10 21 11 19.42 11H16V4.01C16 2.9 16.91 2 18.02 2C19.11 2.01 20.11 2.45 20.83 3.17C21.55 3.9 22 4.9 22 6Z"
									stroke="#949395"
									stroke-width="1.5"
									stroke-miterlimit="10"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M2 7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z"
									stroke="#949395"
									stroke-width="1.5"
									stroke-miterlimit="10"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M6.27002 13.73L11.73 8.27002"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M11.9247 13.5H11.9337"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M6.1947 8.5H6.20368"
									stroke="#949395"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						The standard commission is $ {currentSettings.outputFee}
					</div>
				</div>

				<Button onClick={onClose}>Okay</Button>
			</div>
		</ActionPopup>
	);
};

export default StatusPopup;
