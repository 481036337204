import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from 'common/interfaces';
import { settingsService } from 'services/services';
import { ISettings, IStatusSettings } from 'services/services.interface';

interface ISettingsInitialState {
	settings: ISettings<number>[];
	status: IStatusSettings;
	support: string;
}

const initialState: ISettingsInitialState = {
	settings: [],
	support: '',
	status: {
		beginner: {
			balanceToUpgrade: 100,
			dayToUpgrade: 7,
			strategyFee: 0,
			outputFee: 4,
			percentReferral: 1,
			holdingsLimit: 500,
		},
		bronze: {
			balanceToUpgrade: 200,
			dayToUpgrade: 14,
			strategyFee: 1,
			outputFee: 1,
			percentReferral: 3,
			holdingsLimit: 1000,
		},
		silver: {
			balanceToUpgrade: 1000,
			dayToUpgrade: 30,
			strategyFee: 3,
			outputFee: 0.75,
			percentReferral: 4,
			holdingsLimit: 5000,
		},
		gold: {
			balanceToUpgrade: 5000,
			dayToUpgrade: 60,
			strategyFee: 5,
			outputFee: 0.5,
			percentReferral: 5,
			holdingsLimit: 20000,
		},
		platinum: {
			balanceToUpgrade: 20000,
			dayToUpgrade: 90,
			strategyFee: 7,
			outputFee: 0,
			percentReferral: 7,
			holdingsLimit: null,
		},
	},
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSettings.fulfilled, (state, { payload }) => {
			state.settings = payload.settings;

			state.status = payload.status;
		});
		builder.addCase(getSupport.fulfilled, (state, { payload }) => {
			state.support = payload;
		});
		builder.addCase(updateSupport.fulfilled, (state, { payload }) => {
			state.support = payload;
		});
	},
});

export const settingsReducer = settingsSlice.reducer;
// export const { } = settingsSlice.actions;

export const getSupport = createAsyncThunk<string, void, AsyncThunkConfig>(
	'settings/getSupport',
	async (_, { rejectWithValue }) => {
		try {
			return await settingsService.getSupport();
		} catch (error) {
			return rejectWithValue('[ERROR]: settings/getSupport');
		}
	}
);

export const updateSupport = createAsyncThunk<string, string, AsyncThunkConfig>(
	'settings/updateSupport',
	async (name, { rejectWithValue }) => {
		try {
			return await settingsService.updateSupport(name);
		} catch (error) {
			return rejectWithValue('[ERROR]: settings/updateSupport');
		}
	}
);

export const getSettings = createAsyncThunk<
	{ settings: ISettings<number>[]; status: IStatusSettings },
	void,
	AsyncThunkConfig
>('settings/getSettings', async (_, { rejectWithValue }) => {
	try {
		return await settingsService.getAll();
	} catch (error) {
		return rejectWithValue('[ERROR]: settings/getSettings');
	}
});

export const updateStatus = createAsyncThunk<void, IStatusSettings, AsyncThunkConfig>(
	'settings/updateStatus',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			await settingsService.updateStatus(data);
			await dispatch(getSettings());
		} catch (error) {
			return rejectWithValue('[ERROR]: settings/updateStatus');
		}
	}
);

export const setSettings = createAsyncThunk<void, ISettings<number>[], AsyncThunkConfig>(
	'settings/setSettings',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			for (const el of data) {
				await settingsService.update(el.name, el.value.toString());
			}
			await dispatch(getSettings());
		} catch (error) {
			return rejectWithValue('[ERROR]: settings/setSettings');
		}
	}
);
