import { FC } from 'react';

import ActionPopup from 'components/ActionPopup/ActionPopup';
import { CircleInfoIcon } from 'icons';

import styles from './DevPopup.module.scss';
import Button from 'components/Button/Button';

interface IDevPopup {
	show: boolean;
	onClose: () => void;
}

const DevPopup: FC<IDevPopup> = ({ show, onClose }) => {
	return (
		<ActionPopup show={show} onClose={onClose}>
			<div className={styles.wrapper}>
				<div className={styles.icon} style={{ marginBottom: 4 }}>
					<CircleInfoIcon />
				</div>
				<div className={styles.title}>This feature is currently under development</div>
				<div className={styles.info} style={{ marginBottom: 4 }}>
					As soon as she appears, we will tell you right away
				</div>
				<Button onClick={onClose} size="small" style={{ alignSelf: 'stretch' }}>
					Okey
				</Button>
			</div>
		</ActionPopup>
	);
};

export default DevPopup;
