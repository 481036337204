import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createStrategy } from 'store/reducers/strategySlice';
import { setBackBtn } from 'common/utils';
import { useAppDispatch } from 'common/hooks';
import { Button, Input, Strategies } from 'components';

import styles from './AdminStrategies.module.scss';
import { StatusType } from 'services/services.interface';

const AdminStrategies: FC = () => {
	const navigate = useNavigate();
	setBackBtn(() => navigate('/admin'));

	const [file, setFile] = useState<File | undefined>(undefined);
	const [data, setData] = useState({
		name: '',
		percent: '',
		days: '',
		minDeposit: '',
		maxDeposit: '',
		status: 'beginner' as StatusType,
	});
	const [error, setError] = useState('');

	const dispatch = useAppDispatch();

	const onClick = () => {
		if (+data.days <= 0) {
			setError('Кол-во дней должно быть положительным');
			return;
		}
		if (+data.percent <= 0) {
			setError('Процент должен быть положительным');
			return;
		}
		if (+data.minDeposit < 0) {
			setError('Мин. депозит должен быть положительным');
			return;
		}
		if (+data.maxDeposit <= 0) {
			setError('Макс. депозит должен быть положительным');
			return;
		}
		if (+data.maxDeposit - +data.minDeposit <= 0) {
			setError('Мин. депозит должен быть больше Макс. депозита');
			return;
		}

		dispatch(
			createStrategy({
				name: data.name,
				days: Math.round(+data.days),
				maxDeposit: +data.maxDeposit,
				minDeposit: +data.minDeposit,
				rate: +data.percent / 100 + 1,
				file,
				status: data.status,
			})
		);

		setError('');
		setData({
			name: '',
			percent: '',
			days: '',
			minDeposit: '',
			maxDeposit: '',
			status: 'beginner',
		});
	};

	return (
		<div className={styles.wrapper}>
			<form className={styles.form}>
				<Input
					value={data.name}
					onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
					placeholder="name"
				/>
				<Input
					value={data.days}
					onChange={(e) => setData((prev) => ({ ...prev, days: e.target.value }))}
					type="number"
					pattern="[0-9]*"
					inputMode="decimal"
					placeholder="days"
				/>
				<Input
					value={data.percent}
					onChange={(e) => setData((prev) => ({ ...prev, percent: e.target.value }))}
					type="number"
					pattern="[0-9]*"
					inputMode="decimal"
					placeholder="percent"
				/>
				<Input
					value={data.minDeposit}
					onChange={(e) => setData((prev) => ({ ...prev, minDeposit: e.target.value }))}
					type="number"
					pattern="[0-9]*"
					inputMode="decimal"
					placeholder="min dep"
				/>
				<Input
					value={data.maxDeposit}
					onChange={(e) => setData((prev) => ({ ...prev, maxDeposit: e.target.value }))}
					type="number"
					pattern="[0-9]*"
					inputMode="decimal"
					placeholder="max dep"
				/>

				<div style={{ display: 'flex', gap: 10 }}>
					<p>Статус</p>
					<select
						value={data.status}
						onChange={(e) =>
							setData((prev) => ({ ...prev, status: e.target.value as StatusType }))
						}>
						<option value="beginner">beginner</option>
						<option value="bronze">bronze</option>
						<option value="silver">silver</option>
						<option value="gold">gold</option>
						<option value="platinum">platinum</option>
					</select>
				</div>

				<input
					type="file"
					onChange={(e) => {
						if (e.target.files) setFile(e.target.files[0]);
					}}
				/>
				{error && <p style={{ color: 'var(--red-color)' }}>{error}</p>}
				<Button type="button" className={styles.button} onClick={onClick}>
					Send
				</Button>
			</form>
			<div style={{ marginTop: 30 }} />
			<Strategies isAdminList />
		</div>
	);
};

export default AdminStrategies;
