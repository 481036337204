import { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
	getSettings,
	getSupport,
	setSettings,
	updateStatus,
	updateSupport,
} from 'store/reducers/settingsSlice';
import { ISettings, IStatus, StatusType } from 'services/services.interface';
import { Button, Input } from 'components';

import styles from './AdminSettings.module.scss';
import { SettingsType } from '../../../services/services.interface';
import { useNavigate } from 'react-router-dom';
import { setBackBtn } from 'common/utils';

const statusArray: StatusType[] = ['beginner', 'bronze', 'silver', 'gold', 'platinum'];

const names: { [key in SettingsType]: string } = {
	'min-holding-notification': 'Уведомлять стратегию от',
	'output-fee': 'Комиссия вывода',
	'max-output-without-admin': 'Максимальная сумма вывод без подтверждения',
};

interface IAdminSettings {}

const AdminSettings: FC<IAdminSettings> = ({}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	setBackBtn(() => navigate('/admin'));

	const { settings, status, support } = useAppSelector((state) => state.settings);

	const [changed, setChanged] = useState(false);
	const [data, setData] = useState<ISettings<number>[]>(settings);
	const [statusData, setStatusData] = useState<Record<StatusType, IStatus>>(status);
	const [newSupport, setNewSupport] = useState(support);

	useEffect(() => {
		dispatch(getSettings());
		dispatch(getSupport());
	}, []);

	useEffect(() => {
		setNewSupport(support);
	}, [support]);

	useEffect(() => {
		setData(settings);
	}, [settings]);

	useEffect(() => {
		setStatusData(status);
	}, [status]);

	useEffect(() => {
		let isChanged = false;
		for (const el of data) {
			const current = settings.find((cur) => cur.name === el.name);
			if (current?.value !== el.value) {
				isChanged = true;
			}
		}
		for (const el of statusArray) {
			if (
				status[el].balanceToUpgrade !== statusData[el].balanceToUpgrade ||
				status[el].dayToUpgrade !== statusData[el].dayToUpgrade ||
				status[el].percentReferral !== statusData[el].percentReferral ||
				status[el].strategyFee !== statusData[el].strategyFee ||
				status[el].holdingsLimit !== statusData[el].holdingsLimit ||
				status[el].outputFee !== statusData[el].outputFee
			) {
				isChanged = true;
			}
		}

		if (newSupport !== support) {
			isChanged = true;
		}

		setChanged(isChanged);
	}, [data, statusData, newSupport]);

	const sendChange = () => {
		dispatch(setSettings(data));
		dispatch(updateStatus(statusData));
		dispatch(updateSupport(newSupport));
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Настройки</div>
			<div className={styles.content}>
				{data.map((el) => (
					<div key={el.name} className={styles.item}>
						<div className={styles.label}>{names[el.name]}</div>
						<Input
							onChange={(e) => {
								e.preventDefault();
								setData((prev) =>
									prev.map((prevEl) => {
										if (el.name === prevEl.name) {
											let value = e.target.value;
											if (value.length > 1 && value[0] === '0' && !isNaN(+value[1])) {
												value = value.slice(1);
											}

											return {
												name: prevEl.name,
												value: +value,
											};
										} else return prevEl;
									})
								);
							}}
							className={styles.label}
							value={Number(el.value).toString()}
							rightLabel="USDT"
							type="number"
							pattern="[0-9]*"
							inputMode="decimal"
						/>
					</div>
				))}

				<div className={styles.item}>
					<div className={styles.label}>Аккаунт поддержки</div>
					<Input
						onChange={(e) => {
							e.preventDefault();
							setNewSupport(e.target.value);
						}}
						className={styles.label}
						value={newSupport}
					/>
				</div>

				<div className={styles.status}>
					<div className={styles.title}>Статусы</div>
					{statusArray.map((statusEl) => (
						<div className={styles.status_item}>
							<div>{statusEl}</div>
							<div className={styles.status_inputs}>
								<div>
									<p className={styles.status_input_label}>
										Дней
										<br />
										до апгрейда
									</p>
									<Input
										value={statusData[statusEl].dayToUpgrade}
										onChange={(e) =>
											setStatusData((prev) => ({
												...prev,
												[statusEl]: {
													...prev[statusEl],
													dayToUpgrade: e.target.valueAsNumber,
												},
											}))
										}
										type="number"
										pattern="[0-9]*"
										inputMode="decimal"
									/>
								</div>

								<div>
									<p className={styles.status_input_label}>
										Баланс
										<br />
										на апгрейд
									</p>
									<Input
										value={statusData[statusEl].balanceToUpgrade}
										onChange={(e) =>
											setStatusData((prev) => ({
												...prev,
												[statusEl]: {
													...prev[statusEl],
													balanceToUpgrade: e.target.valueAsNumber,
												},
											}))
										}
										type="number"
										pattern="[0-9]*"
										inputMode="decimal"
									/>
								</div>

								<div>
									<p className={styles.status_input_label}>
										Лимит
										<br />
										вклада
									</p>
									<Input
										value={statusData[statusEl].holdingsLimit || ''}
										onChange={(e) =>
											setStatusData((prev) => ({
												...prev,
												[statusEl]: {
													...prev[statusEl],
													holdingsLimit: e.target.valueAsNumber,
												},
											}))
										}
										type="number"
										pattern="[0-9]*"
										inputMode="decimal"
									/>
								</div>

								<div>
									<p className={styles.status_input_label}>
										Процент
										<br />
										от реферала
									</p>
									<Input
										value={statusData[statusEl].percentReferral}
										onChange={(e) =>
											setStatusData((prev) => ({
												...prev,
												[statusEl]: {
													...prev[statusEl],
													percentReferral: e.target.valueAsNumber,
												},
											}))
										}
										type="number"
										pattern="[0-9]*"
										inputMode="decimal"
									/>
								</div>

								<div>
									<p className={styles.status_input_label}>
										Комиссия
										<br />
										стратегии
									</p>
									<Input
										value={statusData[statusEl].strategyFee}
										onChange={(e) =>
											setStatusData((prev) => ({
												...prev,
												[statusEl]: {
													...prev[statusEl],
													strategyFee: e.target.valueAsNumber,
												},
											}))
										}
										type="number"
										pattern="[0-9]*"
										inputMode="decimal"
									/>
								</div>

								<div>
									<p className={styles.status_input_label}>
										Комиссия
										<br />
										на вывод
									</p>
									<Input
										value={statusData[statusEl].outputFee}
										onChange={(e) =>
											setStatusData((prev) => ({
												...prev,
												[statusEl]: {
													...prev[statusEl],
													outputFee: e.target.valueAsNumber,
												},
											}))
										}
										type="number"
										pattern="[0-9]*"
										inputMode="decimal"
									/>
								</div>
							</div>
						</div>
					))}
				</div>

				{changed && (
					<Button
						style={{ position: 'fixed', bottom: 20, right: 20, left: 20 }}
						onClick={sendChange}>
						Сохранить
					</Button>
				)}
			</div>
		</div>
	);
};

export default AdminSettings;
