import { useAppDispatch, useAppSelector } from 'common/hooks';
import { Button, Input } from 'components';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAdmins, setAdmin } from 'store/reducers/userSlice';

interface IAdminAdmins {}

const AdminAdmins: FC<IAdminAdmins> = ({}) => {
	const dispatch = useAppDispatch();

	const { admins } = useAppSelector((state) => state.user);

	const [role, setRole] = useState<'MAIN' | 'DEFAULT' | null>(null);
	const [nameId, setNameId] = useState('');

	useEffect(() => {
		dispatch(getAdmins());
	}, []);

	return (
		<div>
			<Input value={nameId} onChange={(e) => setNameId(e.target.value)} />

			<select
				value={role || '-'}
				onChange={(e) => {
					const newRole = e.target.value as 'MAIN' | 'DEFAULT' | '-';

					setRole(newRole === '-' ? null : newRole);
				}}>
				<option value="MAIN">Главный</option>
				<option value="DEFAULT">Поддержка</option>
				<option value="-">-</option>
			</select>

			{nameId && (
				<Button
					onClick={async () => {
						let tgId: number | undefined = undefined;
						let name: string | undefined = undefined;

						if (isNaN(+nameId)) {
							name = nameId;
						} else {
							tgId = +nameId;
						}

						const action = await dispatch(setAdmin({ id: tgId, name, role }));

						if (setAdmin.fulfilled.match(action)) {
							setNameId('');
							setRole(null);
						}
					}}>
					Add admin
				</Button>
			)}

			<div style={{ marginTop: 20 }}>
				{admins.map((el) => (
					<div
						key={el.id}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Link to={`http://t.me/${el.username}`} target="_blank">
							{el.username}
						</Link>

						<select
							value={el.Admin.role}
							onChange={(e) => {
								const newRole = e.target.value as 'MAIN' | 'DEFAULT';

								dispatch(setAdmin({ id: +el.tgId, role: newRole }));
							}}>
							<option value="MAIN">Главный</option>
							<option value="DEFAULT">Поддержка</option>
						</select>

						<button
							onClick={() => dispatch(setAdmin({ id: +el.tgId, role: null }))}
							style={{ color: 'red' }}>
							Del
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default AdminAdmins;
