import axios from 'axios';

import { store } from 'store/createStore';
import { setNotice } from 'store/reducers/appSlice';

export const baseConfig = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
});

baseConfig.interceptors.response.use(
	async (res) => {
		try {
			const token = res.data.token;

			if (token) {
				localStorage.setItem('token', token);
			}
		} catch (e) {
			store.dispatch(setNotice({ message: `${e}`, type: 'error' }));
		}
		return res;
	},
	(error) => {
		const setError = (message: string) => setNotice({ message, type: 'error' });
		const status = error.response?.status;

		if (status >= 500) {
			store.dispatch(setError('Упс! Что-то пошло не так'));
		} else if (error.code === 'ERR_NETWORK') {
			store.dispatch(setError(JSON.stringify(error)));
		} else {
		}
		return Promise.reject(error);
	}
);

baseConfig.interceptors.request.use(
	(req) => {
		const token = localStorage.getItem('token');

		if (token) {
			req.headers['access-token'] = token;
		}
		return req;
	},
	(error) => Promise.reject(error)
);
