import { FC, useEffect } from 'react';

import { TransActionEnum, TransStatusEnum } from 'common/interfaces';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { cancelOutputRequest, getOutputRequest } from 'store/reducers/userSlice';
import Transaction from 'components/Transaction/Transaction';

import styles from './OutputRequest.module.scss';
import Button from 'components/Button/Button';
import { setNotice } from 'store/reducers/appSlice';

const OutputRequest: FC = () => {
	const dispatch = useAppDispatch();

	const { outputReq } = useAppSelector((state) => state.user);

	useEffect(() => {
		dispatch(getOutputRequest());
	}, [dispatch]);

	return outputReq ? (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				Payment processing <br /> may take up to 24 hours
			</div>
			<Transaction
				data={{
					id: outputReq.id,
					action: TransActionEnum.OUTPUT,
					trId: outputReq.address,
					createdAt: outputReq.createdAt,
					sum: outputReq.sum,
					status: TransStatusEnum.WAIT,
				}}
				isWait
			/>
			<Button
				size="small"
				color="red"
				onClick={async () => {
					const action = await dispatch(cancelOutputRequest());

					if (cancelOutputRequest.fulfilled.match(action)) {
						dispatch(
							setNotice({
								type: 'success',
								message: 'Transaction canceled',
							})
						);
					}
				}}>
				Cancel Transaction
			</Button>
		</div>
	) : (
		<></>
	);
};

export default OutputRequest;
