import { FC } from 'react';

import styles from './Transaction.module.scss';
import cn from 'classnames';
import { TransActionEnum, TransStatusEnum } from 'common/interfaces';
import { ITransaction } from 'services/services.interface';
import { useUtils } from '@telegram-apps/sdk-react';

interface ITransactionProps {
	data: ITransaction;
	isWait?: boolean;
}

const Transaction: FC<ITransactionProps> = ({ data, isWait }) => {
	const utils = useUtils();

	const url = isWait ? 'address' : 'transaction';

	return (
		<div className={styles.wrapper}>
			<div className={styles.info}>
				<button
					onClick={() =>
						utils.openLink(`${process.env.REACT_APP_TRON_URL}/#/${url}/${data.trId}`)
					}
					className={styles.title}>
					{data.trId.substring(0, 4) + '***' + data.trId.substring(data.trId.length - 4)}
				</button>
				<div className={styles.date}>{new Date(data.createdAt).toLocaleString()}</div>
			</div>
			<div className={styles.value}>
				<div
					className={cn(styles.sum, {
						[styles.green]: !isWait && data.action === TransActionEnum.INPUT,
					})}>
					{data.sum} USDT
				</div>
				<div
					className={cn(styles.status, {
						[styles.progress]: isWait,
						[styles.green]: !isWait && data.action === TransActionEnum.INPUT,
					})}>
					<p>
						{isWait
							? 'In progress'
							: data.action === TransActionEnum.INPUT
							? 'Received'
							: 'Sent'}
					</p>
					{data.status === TransStatusEnum.FAILED && (
						<p style={{ color: 'var(--red-color)' }}>(Failed)</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default Transaction;
