import { FC, useRef, useState } from 'react';
import { postEvent } from '@telegram-apps/sdk-react';
import { Link, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setBackBtn } from 'common/utils';
import { ActionPopup, Button, CopiedPopup, QrCode } from 'components';
import { CopyIcon, InfoIcon, WalletCheckIcon } from 'icons';

import usdtImg from 'assets/img/usdt.svg';
import styles from './Payment.module.scss';
import { setLastPage, toggleInDev } from 'store/reducers/appSlice';

const Payment: FC = () => {
	const timerRef = useRef<NodeJS.Timeout>();
	const [copied, setCopied] = useState(0);
	const [isInfo, setIsInfo] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { lastPage } = useAppSelector((state) => state.app);

	setBackBtn(() => {
		dispatch(setLastPage(null));
		navigate(lastPage || '/');
	});

	const { wallet } = useAppSelector((state) => state.user);

	const onCopyHandler = () => {
		clearTimeout(timerRef.current);

		navigator.clipboard.writeText(wallet.address);

		try {
			postEvent('web_app_trigger_haptic_feedback', {
				type: 'notification',
				notification_type: copied !== 0 ? 'error' : 'success',
			});
		} catch {}

		setCopied((prev) => prev + 1);

		timerRef.current = setTimeout(() => {
			setCopied(0);
		}, 3000);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.title}>Your address in USD</div>
				<button onClick={() => setIsInfo(true)} className={styles.info_btn}>
					<InfoIcon />
				</button>
			</div>
			<div className={styles.info}>
				Send only <b>USDT TRC20</b> to this address. Other assets will be permanently
				lost. By sending coins, you agree to the{' '}
				<Link className={styles.link} to="/">
					offer
				</Link>
				.
			</div>
			<div className={styles.qrCode}>
				<QrCode address={wallet.address} />
				<p>Scan the QR code to send USDT to your Wallet.</p>
			</div>
			<div className={styles.code}>
				<p className={styles.codeTitle}>Your USDT TRC20 address</p>
				<div onClick={onCopyHandler}>
					<p className={styles.codeCode}>{wallet.address}</p>
					<Button className={styles.codeCopy} size="small">
						<CopyIcon />
						Copy
					</Button>
				</div>
			</div>
			<Button
				style={{ alignSelf: 'stretch' }}
				color="white"
				onClick={() => dispatch(toggleInDev())}>
				<WalletCheckIcon />
				Open in Wallet
			</Button>

			<CopiedPopup show={copied} />
			<ActionPopup show={isInfo} onClose={() => setIsInfo(false)}>
				<div className={styles.walletInfo_content}>
					<img width={60} height={60} src={usdtImg} alt="usdt" />
					<div className={styles.walletInfo_info}>
						<div className={styles.walletInfo_title}>Dollars in Wallet is USDT</div>
						<p className={styles.walletInfo_text}>
							Dollars in Wallets stand for USDT (Tether) cryptocurrency as its price
							always roughly equal to US dollar.
						</p>
					</div>
					<Button
						onClick={() => setIsInfo(false)}
						size="small"
						className={styles.walletInfo_btn}>
						Okey
					</Button>
				</div>
			</ActionPopup>
		</div>
	);
};

export default Payment;
