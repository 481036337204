import { SVGProps } from 'react';

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={12}
		height={12}
		viewBox="0 0 12 12"
		fill="none"
		{...props}>
		<path
			stroke="var(--hint-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			d="m4.455 9.96 3.26-3.26a.993.993 0 0 0 0-1.4l-3.26-3.26"
		/>
	</svg>
);
export default ArrowIcon;
