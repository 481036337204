import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { referralTokenService, setBackBtn } from 'common/utils';

import styles from './Referrals.module.scss';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { getReferralActions } from 'store/reducers/userSlice';
import cn from 'classnames';
import { postEvent } from '@telegram-apps/sdk-react';
import { CopiedPopup } from 'components';

const Referrals: FC = () => {
	const linkRef = useRef<HTMLDivElement>(null);
	const pRef = useRef<HTMLDivElement>(null);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	setBackBtn(() => navigate('/'));

	const timerRef = useRef<NodeJS.Timeout>();
	const [copied, setCopied] = useState(0);

	const onCopyHandler = () => {
		clearTimeout(timerRef.current);

		navigator.clipboard.writeText(link);

		try {
			postEvent('web_app_trigger_haptic_feedback', {
				type: 'notification',
				notification_type: copied !== 0 ? 'error' : 'success',
			});
		} catch {}

		setCopied((prev) => prev + 1);

		timerRef.current = setTimeout(() => {
			setCopied(0);
		}, 3000);
	};

	const {
		referralActions,
		user,
		status: userStatus,
	} = useAppSelector((state) => state.user);
	const { status } = useAppSelector((state) => state.settings);

	const link = `https://t.me/F1nexbot/Finex?startapp=${referralTokenService.generate(
		user.id
	)}`;

	const [linkValue, setLinkValue] = useState(link);

	const checkWidth = () => {
		if (!linkRef.current || !pRef.current) return;

		const scrollWidth = linkRef.current.scrollWidth;
		const width = linkRef.current.clientWidth;

		if (scrollWidth > width) {
			setLinkValue((prev) => link.slice(0, prev.length - 5) + '...');
		} else if (pRef.current.clientWidth < width - 70) {
			setLinkValue((prev) =>
				prev.length - 3 === link.length ? link : link.slice(0, prev.length) + '...'
			);
		}
	};

	useEffect(() => {
		dispatch(getReferralActions());

		if (!linkRef.current) return;
		const ro = new ResizeObserver(checkWidth);
		ro.observe(linkRef.current);

		return () => {
			ro.disconnect();
		};
	}, []);

	useEffect(() => {
		checkWidth();
	}, [linkValue]);

	const actionsData = useMemo(() => {
		const result = {
			count: 0,
			sum: 0,
		};

		referralActions.forEach((el) => {
			if (el.sum === null) {
				result.count += 1;
			} else {
				result.sum += el.sum;
			}
		});

		return result;
	}, [referralActions]);

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>My Referrals</h2>

			<div className={styles.steps}>
				<div className={styles.step}>
					<p className={styles.step_title}>1 step</p>
					<p className={styles.step_info}>Share your&nbsp;link</p>
				</div>
				<div className={styles.step}>
					<p className={styles.step_title}>2 step</p>
					<p className={styles.step_info}>Your referral invests in a strategy</p>
				</div>
				<div className={styles.step}>
					<p className={styles.step_title}>3 step</p>
					<p className={styles.step_info}>Earn a 5% commission</p>
				</div>
			</div>

			<div className={styles.link}>
				<p className={styles.link_title}>My referral link</p>
				<div onClick={onCopyHandler} ref={linkRef} className={styles.link_value}>
					<p ref={pRef} title={link}>
						{linkValue}
					</p>

					<div className={styles.link_icon}>
						<svg
							width="16"
							height="17"
							viewBox="0 0 16 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.6654 9.09992V11.8999C10.6654 14.2333 9.73203 15.1666 7.3987 15.1666H4.5987C2.26536 15.1666 1.33203 14.2333 1.33203 11.8999V9.09992C1.33203 6.76659 2.26536 5.83325 4.5987 5.83325H7.3987C9.73203 5.83325 10.6654 6.76659 10.6654 9.09992Z"
								stroke="#007AFF"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M14.6654 5.09992V7.89992C14.6654 10.2333 13.732 11.1666 11.3987 11.1666H10.6654V9.09992C10.6654 6.76659 9.73203 5.83325 7.3987 5.83325H5.33203V5.09992C5.33203 2.76659 6.26536 1.83325 8.5987 1.83325H11.3987C13.732 1.83325 14.6654 2.76659 14.6654 5.09992Z"
								stroke="#007AFF"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
				</div>
			</div>

			<div className={styles.info_block}>
				{actionsData.count > 0 && (
					<div className={styles.info}>
						<p className={styles.info_title}>
							Your
							<br />
							commission
						</p>
						<p className={styles.info_percent}>
							{status[userStatus || 'beginner'].percentReferral} <span>%</span>
						</p>
					</div>
				)}
				<div className={styles.info}>
					<p className={styles.info_title}>
						Total
						<br />
						Referrals
					</p>
					<p className={styles.info_value}>{actionsData.count}</p>
				</div>
				<div className={styles.info}>
					<p className={styles.info_title}>
						Total
						<br />
						Commission
					</p>
					<p className={styles.info_value}>{actionsData.sum} USDT</p>
				</div>
			</div>

			{referralActions.length > 0 && (
				<div className={styles.actions}>
					<p className={styles.actions_title}>Referral Activity </p>
					<div className={styles.list}>
						{referralActions.map((el) => (
							<div key={el.id} className={styles.item}>
								<div className={styles.block_1}>
									<p className={styles.name}>{el.username || '-'}</p>
									<p className={styles.date}>{new Date(el.createdAt).toLocaleString()}</p>
								</div>
								<div className={styles.block_2}>
									{el.sum !== null && (
										<div className={styles.sum}>
											{el.sum} USDT • {el.percent}%
										</div>
									)}
									<p className={cn(styles.action, { [styles.green]: el.sum !== null })}>
										{el.sum === null ? 'Registration' : 'Commission'}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			)}

			<CopiedPopup show={copied} />
		</div>
	);
};

export default Referrals;
