import { FC, useEffect, useState } from 'react';

import { confirmOutputRequests, getOutputRequests } from 'store/reducers/adminSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks';

import styles from './AdminOutputRequests.module.scss';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { setBackBtn } from 'common/utils';
import { Button, Transaction } from 'components';
import { TransActionEnum, TransStatusEnum } from 'common/interfaces';

interface IAdminOutputRequests {}

const AdminOutputRequests: FC<IAdminOutputRequests> = ({}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	setBackBtn(() => {
		navigate('/admin');
	});

	const [currentConfirm, setCurrentConfirm] = useState<[number, boolean] | null>(null);
	const { outputReqs } = useAppSelector((state) => state.admin);

	useEffect(() => {
		dispatch(getOutputRequests());
	}, []);

	const onClickHandler = (id: number, confirmed: boolean) => {
		setCurrentConfirm([id, confirmed]);
	};

	const onConfirm = (id: number, confirmed: boolean) => {
		dispatch(
			confirmOutputRequests({
				id,
				confirmed,
			})
		);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Запросы на вывод</div>
			<div className={styles.list}>
				{outputReqs.map((el) => (
					<div className={styles.item}>
						<Transaction
							data={{
								id: el.id,
								sum: el.sum,
								trId: el.address,
								createdAt: el.createdAt,
								action: TransActionEnum.OUTPUT,
								status: TransStatusEnum.WAIT,
							}}
						/>
						<div className={styles.info}>
							<Link to={`/admin/user/${el.userId}`}> user: {el.userId}</Link>
							<div className={styles.buttons}>
								<button
									onClick={() => {
										onClickHandler(el.id, true);
									}}
									className={cn(styles.button, styles.yes)}>
									ДА
								</button>
								<button
									onClick={() => {
										onClickHandler(el.id, false);
									}}
									className={cn(styles.button, styles.no)}>
									НЕТ
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
			{currentConfirm && (
				<div className={styles.confirm}>
					<div className={styles.confirm_content}>
						<div className={styles.confirm_title}>
							{currentConfirm[1] ? 'Подтвердить' : 'Отменить'} вывод?
						</div>
						<div className={styles.confirm_buttons}>
							<Button
								color="white"
								onClick={() => {
									setCurrentConfirm(null);
								}}
								className={styles.confirm_button}>
								Нет
							</Button>
							<Button
								onClick={() => {
									onConfirm(...currentConfirm);
									setCurrentConfirm(null);
								}}
								className={styles.confirm_button}>
								Да
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AdminOutputRequests;
