import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { deleteStrategy, getStrategies } from 'store/reducers/strategySlice';

import deleteImg from 'assets/img/delete.svg';
import styles from './Strategies.module.scss';
import { StatusType } from 'services/services.interface';
import ActionPopup from 'components/ActionPopup/ActionPopup';
import { InfoIcon } from 'icons';
import BonusInfo from './BonusInfo';

const statusNumber: Record<StatusType, number> = {
	beginner: 0,
	bronze: 1,
	silver: 2,
	gold: 3,
	platinum: 4,
};

interface IStrategies {
	isAdminList?: boolean;
}

const Strategies: FC<IStrategies> = ({ isAdminList = false }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { strategies } = useAppSelector((state) => state.strategy);
	const { status } = useAppSelector((state) => state.user);
	const { status: statusSettings } = useAppSelector((state) => state.settings);

	const [info, setInfo] = useState<number | null>(null);

	useEffect(() => {
		dispatch(getStrategies());
	}, [dispatch]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Available strategies</div>
			<div className={styles.list}>
				{strategies
					.filter((el) =>
						isAdminList
							? true
							: statusNumber[status || 'beginner'] >= statusNumber[el.status]
					)
					.map((s) => (
						<div
							key={s.id}
							onClick={!isAdminList ? () => navigate(`/strategy/${s.id}`) : undefined}
							className={cn(styles.item, styles[s.status], {
								[styles.hover]: !isAdminList,
							})}>
							{s.status !== 'beginner' && <div className={styles.special}>SPECIAL</div>}

							<p className={styles.name}>
								<div className={styles.image}>
									{s.image && (
										<img
											src={process.env.REACT_APP_API_URL + `/uploads/${s.image}`}
											onError={(e) => {
												e.currentTarget.style.display = 'none';
											}}
											alt="Strategy icon"
										/>
									)}
								</div>
								{s.name}
							</p>
							<p className={styles.percent}>
								+
								{(
									(status && status !== 'beginner'
										? statusSettings[status].strategyFee
										: 0) +
									(s.rate - 1) * 100
								).toFixed(2)}
								%
								{!isAdminList && (
									<button
										style={{
											background: 'transparent',
											width: 24,
											height: 24,
											display: 'inline-flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
										onClick={(e) => {
											e.stopPropagation();
											setInfo(s.rate);
										}}>
										<InfoIcon width={16} height={16} color="grey" />
									</button>
								)}
							</p>
							<p className={styles.days}>
								Placement period:
								<p>{s.days} days</p>
							</p>
							<p className={styles.deposit}>
								<p>Deposit (USDT)</p>
								<p>
									{s.minDeposit} - {s.maxDeposit}
								</p>
							</p>
							{isAdminList && (
								<button
									onClick={() => dispatch(deleteStrategy(s.id))}
									className={styles.delete}>
									<img src={deleteImg} alt="delete" />
								</button>
							)}
						</div>
					))}
			</div>

			<ActionPopup show={!!info} onClose={() => setInfo(null)}>
				<BonusInfo
					rate={info}
					onClose={() => setInfo(null)}
					status={status}
					dopRate={(status && statusSettings[status].strategyFee / 100) || null}
				/>
			</ActionPopup>
		</div>
	);
};

export default Strategies;
