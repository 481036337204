import { initBackButton } from '@telegram-apps/sdk-react';

export const setBackBtn = (func: () => void) => {
	try {
		const backButton = initBackButton()[0];
		backButton.show();
		backButton.on('click', () => {
			func();
			backButton.hide();
		});
	} catch {}
};

export const referralTokenService = {
	_start: 'Kjh2l',
	_end: 'kms7Js1L',

	generate(id: number) {
		return `${this._start}${id}${this._end}`;
	},
	verify(token: string) {
		if (!token.startsWith(this._start) || !token.endsWith(this._end)) {
			return null;
		}

		const id = +token.slice(this._start.length + 1, token.length - this._end.length);

		if (id) return null;

		return id;
	},
};
