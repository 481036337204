import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from 'common/interfaces';
import { authService } from 'services/services';
import { getSession, getUser } from './userSlice';
import { ILoginRequest } from 'services/services.interface';

export interface INotice {
	message: string;
	type: 'error' | 'success';
}

interface IAppInitialState {
	isAppInitialized: boolean;
	notice: INotice | null;
	loading: boolean;
	inDev: boolean;
	lastPage: string | null;
}

const initialState: IAppInitialState = {
	isAppInitialized: false,
	notice: null,
	loading: false,
	inDev: false,
	lastPage: null,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setNotice: (state, action: PayloadAction<INotice | null>) => {
			state.notice = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		toggleInDev: (state) => {
			state.inDev = !state.inDev;
		},
		setLastPage: (state, { payload }: PayloadAction<string | null>) => {
			state.lastPage = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(initialize.fulfilled, (state) => {
			state.isAppInitialized = true;
		});
		builder.addCase(initialize.rejected, (state) => {
			state.isAppInitialized = false;
		});
	},
});

export const appReducer = appSlice.reducer;
export const { setNotice, setLoading, toggleInDev, setLastPage } = appSlice.actions;

export const initialize = createAsyncThunk<void, ILoginRequest, AsyncThunkConfig>(
	'app/initialize',
	async (data, { dispatch, rejectWithValue }) => {
		dispatch(setLoading(true));
		try {
			await authService.login(data);
			await dispatch(getSession());
			await dispatch(getUser());
		} catch (error) {
			dispatch(setNotice({ message: `${error}`, type: 'error' }));
			return rejectWithValue('[initialize]: Error');
		} finally {
			dispatch(setLoading(false));
		}
	}
);
