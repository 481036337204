import { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { getSettings } from 'store/reducers/settingsSlice';
import { getHoldingsStats } from 'store/reducers/holdingSlice';
import StatusInfo from './StatusInfo';

import StatusPopup from './StatusPopup';

const Status: FC = () => {
	const dispatch = useAppDispatch();

	const {
		status: userStatus,
		statusDays,
		statusMissingDays,
	} = useAppSelector((state) => state.user);
	const { status: statusSettings } = useAppSelector((state) => state.settings);
	const { stats } = useAppSelector((state) => state.holding);

	const [isPopup, setIsPopup] = useState(false);

	useEffect(() => {
		dispatch(getSettings());
		dispatch(getHoldingsStats());
	}, [dispatch]);

	const status = userStatus || 'beginner';

	const currentSettings = statusSettings[status];

	const nextSettings =
		status === 'platinum'
			? undefined
			: statusSettings[
					status === 'beginner'
						? 'bronze'
						: status === 'bronze'
						? 'silver'
						: status === 'silver'
						? 'gold'
						: status === 'gold'
						? 'platinum'
						: 'platinum'
			  ];

	const maxDays = currentSettings.dayToUpgrade;
	const percent = currentSettings.strategyFee;
	const sumToDayUpgrade = currentSettings.balanceToUpgrade;
	const sumToStatusUpgrade = nextSettings?.balanceToUpgrade || null;

	const currentSum = stats?.currentBalance || 0;

	return (
		<div>
			<div onClick={() => setIsPopup(true)}>
				<StatusInfo
					statusDays={statusDays}
					maxDays={maxDays}
					percent={percent}
					status={status}
					sumToDayUpgrade={sumToDayUpgrade}
					sumToStatusUpgrade={sumToStatusUpgrade}
					currentSum={currentSum}
					statusMissingDays={statusMissingDays}
				/>
			</div>

			<StatusPopup
				currentSettings={currentSettings}
				currentSum={currentSum}
				maxDays={maxDays}
				onClose={() => setIsPopup(false)}
				percent={percent}
				show={isPopup}
				status={status}
				statusDays={statusDays}
				statusMissingDays={statusMissingDays}
				sumToDayUpgrade={sumToDayUpgrade}
				sumToStatusUpgrade={sumToStatusUpgrade}
			/>
		</div>
	);
};

export default Status;
