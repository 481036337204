import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncThunkConfig, TransActionEnum } from 'common/interfaces';
import { authService, transactionService, userService } from 'services/services';
import {
	IAdminUser,
	ICreateOutputRequest,
	IOutputRequest,
	IReferralAction,
	ISessionResponse,
	ISetAdmin,
	ITransaction,
	IUser,
	IWallet,
	StatusType,
} from 'services/services.interface';
import { createHolding } from './holdingSlice';
import { setNotice } from './appSlice';

interface IUserInitialState {
	user: ISessionResponse;
	wallet: IWallet;

	status: StatusType | null;
	statusDays: number;
	statusMissingDays: number;

	outputReq: IOutputRequest | null;
	transactions: ITransaction[];
	referralActions: IReferralAction[];

	admins: IAdminUser[];
}

const initialState: IUserInitialState = {
	user: {
		id: 0,
		tgId: '0',
		role: 'USER',
	},
	wallet: {
		address: '',
		availableSum: 0,
	},

	status: null,
	statusDays: 0,
	statusMissingDays: 0,

	outputReq: null,
	transactions: [],
	referralActions: [],

	admins: [],
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSession.fulfilled, (state, { payload }) => {
			state.user = payload;
		});
		builder.addCase(getUser.fulfilled, (state, { payload }) => {
			state.wallet = payload.wallet;
			state.status = payload.status;
			state.statusDays = payload.statusDays;
			state.statusMissingDays = payload.statusMissingDays;
		});
		builder.addCase(createHolding.fulfilled, (state, { payload }) => {
			state.wallet.availableSum -= payload.sum;
		});
		builder.addCase(checkWallet.fulfilled, (state, { payload }) => {
			if (payload) state.wallet.availableSum = payload;
		});
		builder.addCase(getReferralActions.fulfilled, (state, { payload }) => {
			if (payload) state.referralActions = payload;
		});

		builder.addCase(getSession.rejected, (state) => {
			return initialState;
		});

		builder.addCase(getTransactions.fulfilled, (state, { payload }) => {
			state.transactions = payload;
		});

		builder.addCase(getOutputRequest.fulfilled, (state, { payload }) => {
			state.outputReq = payload;
		});
		builder.addCase(outputRequest.fulfilled, (state, { payload }) => {
			if ('action' in payload && 'status' in payload) {
				state.transactions.unshift(payload);
			} else {
				state.outputReq = payload;
			}
		});

		builder.addCase(getAdmins.fulfilled, (state, { payload }) => {
			state.admins = payload;
		});
	},
});

export const userReducer = userSlice.reducer;
// export const { * } = userSlice.actions;

export const getSession = createAsyncThunk<ISessionResponse, void, AsyncThunkConfig>(
	'user/getSession',
	async (_, { rejectWithValue }) => {
		try {
			return await authService.getSession();
		} catch (error) {
			return rejectWithValue('[getSession]: Error');
		}
	}
);

export const getUser = createAsyncThunk<IUser, void, AsyncThunkConfig>(
	'user/getUser',
	async (_, { rejectWithValue, getState }) => {
		try {
			const id = getState().user.user?.id;
			if (!id) return rejectWithValue('[getUser]: no user');

			return await userService.getUser(id);
		} catch (error) {
			return rejectWithValue('[getUser]: Error');
		}
	}
);

export const checkWallet = createAsyncThunk<number | null, void, AsyncThunkConfig>(
	'user/checkWallet',
	async (_, { rejectWithValue }) => {
		try {
			return await transactionService.checkWallet();
		} catch (error) {
			return rejectWithValue('[checkWallet]: Error');
		}
	}
);

export const outputRequest = createAsyncThunk<
	IOutputRequest | ITransaction,
	ICreateOutputRequest,
	AsyncThunkConfig
>('user/outputRequest', async (data, { rejectWithValue, dispatch }) => {
	try {
		return await transactionService.outputRequest(data);
	} catch (error) {
		dispatch(
			setNotice({ message: `Something went wrong, try again later`, type: 'error' })
		);
		return rejectWithValue('[outputRequest]: Error');
	}
});

export const getOutputRequest = createAsyncThunk<
	IOutputRequest | null,
	void,
	AsyncThunkConfig
>('user/getOutputRequest', async (_, { rejectWithValue }) => {
	try {
		return await transactionService.getWaitingOutputRequest();
	} catch (error) {
		return rejectWithValue('[getOutputRequest]: Error');
	}
});

export const cancelOutputRequest = createAsyncThunk<void, void, AsyncThunkConfig>(
	'user/cancelOutputRequest',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			await transactionService.cancelWaitingOutputRequest();
			dispatch(getOutputRequest());
		} catch (error) {
			return rejectWithValue('[cancelOutputRequest]: Error');
		}
	}
);

export const getReferralActions = createAsyncThunk<
	IReferralAction[],
	void,
	AsyncThunkConfig
>('user/getReferralActions', async (_, { rejectWithValue }) => {
	try {
		return await userService.getReferralActions();
	} catch (error) {
		return rejectWithValue('[getReferralActions]: Error');
	}
});

export const getTransactions = createAsyncThunk<
	ITransaction[],
	TransActionEnum | undefined,
	AsyncThunkConfig
>('user/getTransactions', async (action, { rejectWithValue }) => {
	try {
		return await transactionService.getAll(action);
	} catch (error) {
		return rejectWithValue('[getTransactions]: Error');
	}
});

export const getAdmins = createAsyncThunk<IAdminUser[], void, AsyncThunkConfig>(
	'user/getAdmins',
	async (_, { rejectWithValue }) => {
		try {
			return await userService.getAdmins();
		} catch (error) {
			return rejectWithValue('[getAdmins]: Error');
		}
	}
);

export const setAdmin = createAsyncThunk<void, ISetAdmin, AsyncThunkConfig>(
	'user/setAdmin',
	async (body, { rejectWithValue, dispatch }) => {
		try {
			await userService.setAdmin(body);
			await dispatch(getAdmins());
		} catch (error) {
			return rejectWithValue('[setAdmin]: Error');
		}
	}
);
