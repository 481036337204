import React, { FC } from 'react';

import styles from './Status.module.scss';
import { StatusType } from 'services/services.interface';
import cn from 'classnames';

interface IStatusInfo {
	statusDays: number;
	maxDays: number;
	percent: number;
	status: StatusType;

	sumToDayUpgrade: number;
	sumToStatusUpgrade: number | null;
	currentSum: number;
	statusMissingDays: number;
}

const StatusInfo: FC<IStatusInfo> = ({
	statusDays,
	maxDays,
	status,
	percent,
	sumToDayUpgrade,
	sumToStatusUpgrade,
	currentSum,
	statusMissingDays,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<div>{status[0].toUpperCase() + status.slice(1)}</div>
				<div
					className={cn(styles.percent, styles[status], {
						[styles.color_white]: status !== 'beginner',
					})}>
					<span style={{ fontSize: 14, fontWeight: 400 }}>+</span>
					<span style={{ fontSize: 14, fontWeight: 600 }}>{percent}</span>
					<span style={{ fontSize: 11, fontWeight: 400 }}>%</span>
				</div>
			</div>
			<div className={styles.status_line_background}>
				<div
					className={cn(styles.status_line, styles[status])}
					style={{
						right: `${Math.max(
							Math.min((1 - statusDays / maxDays) * 100, 95),
							100
						).toFixed()}%`,
					}}
				/>
			</div>
			<div className={styles.days}>
				{statusDays}/{maxDays} days
			</div>

			{maxDays === statusDays && sumToStatusUpgrade && currentSum < sumToStatusUpgrade ? (
				<p className={styles.success}>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.00016 14.6668C11.6668 14.6668 14.6668 11.6668 14.6668 8.00016C14.6668 4.3335 11.6668 1.3335 8.00016 1.3335C4.3335 1.3335 1.3335 4.3335 1.3335 8.00016C1.3335 11.6668 4.3335 14.6668 8.00016 14.6668Z"
							stroke="var(--green-color)"
							strokeWidth="1.25"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M5.1665 7.99995L7.05317 9.88661L10.8332 6.11328"
							stroke="var(--green-color)"
							strokeWidth="1.25"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					Invest $ {Math.ceil(sumToStatusUpgrade - currentSum)} to raise your level
				</p>
			) : (
				currentSum < sumToDayUpgrade &&
				!(status === 'beginner' && statusDays === 0) && (
					<p className={styles.error}>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M8 6V9.33333"
								stroke="var(--red-color)"
								stroke-width="1.2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M7.99979 14.273H3.95979C1.64646 14.273 0.679793 12.6197 1.79979 10.5997L3.87979 6.85303L5.83979 3.33303C7.02646 1.19303 8.97313 1.19303 10.1598 3.33303L12.1198 6.85969L14.1998 10.6064C15.3198 12.6264 14.3465 14.2797 12.0398 14.2797H7.99979V14.273Z"
								stroke="var(--red-color)"
								stroke-width="1.2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M7.99658 11.3335H8.00257"
								stroke="var(--red-color)"
								stroke-width="1.2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						Invest ${Math.ceil(sumToDayUpgrade - currentSum)} to stay, or start losing in{' '}
						{7 - statusMissingDays} days
					</p>
				)
			)}
		</div>
	);
};

export default StatusInfo;
