import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import {
	bindThemeParamsCSSVars,
	initInitData,
	initMiniApp,
	initThemeParams,
	mockTelegramEnv,
} from '@telegram-apps/sdk';
import eruda from 'eruda';
// TODO - add strategyFee (в каждый компонент + история)

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { initialize, setNotice, toggleInDev } from 'store/reducers/appSlice';
import { router } from 'router/router';
import { DevPopup, Loader } from 'components';
import { referralTokenService } from 'common/utils';

const App = () => {
	const dispatch = useAppDispatch();
	const { isAppInitialized, inDev, notice } = useAppSelector((state) => state.app);
	const { user } = useAppSelector((state) => state.user);

	useEffect(() => {
		try {
			if (
				process.env.REACT_APP_API_URL?.includes('ngrok') ||
				process.env.REACT_APP_API_URL?.includes('localhost')
			)
				mockTelegramEnv({
					themeParams: {
						accent_text_color: '#6ab2f2',
						bg_color: '#17212b',
						button_color: '#5288c1',
						button_text_color: '#ffffff',
						bottom_bar_bg_color: '#ffffff',
						destructive_text_color: '#ec3942',
						header_bg_color: '#17212b',
						hint_color: '#708499',
						link_color: '#6ab3f3',
						secondary_bg_color: '#232e3c',
						section_bg_color: '#17212b',
						section_header_text_color: '#6ab3f3',
						subtitle_text_color: '#708499',
						text_color: '#f5f5f5',
					},
					initData: {
						user: {
							id: 156045434,
							firstName: 'Andrew',
							lastName: 'Rogue',
							username: 'rogue',
							languageCode: 'en',
							isPremium: true,
							allowsWriteToPm: true,
						},
						hash: '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31',
						authDate: new Date(1716922846000),
						startParam: 'debug',
						chatType: 'sender',
						chatInstance: '8428209589180549439',
					},
					initDataRaw: new URLSearchParams([
						[
							'user',
							JSON.stringify({
								id: 156045434,
								first_name: 'Andrew',
								last_name: 'Rogue',
								username: 'rogue',
								language_code: 'en',
								is_premium: true,
								allows_write_to_pm: true,
							}),
						],
						['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
						['auth_date', '1716922846'],
						['start_param', 'debug'],
						['chat_type', 'sender'],
						['chat_instance', '8428209589180549439'],
					]).toString(),
					version: '7.2',
					platform: 'tdesktop',
				});

			const data = initInitData();
			const userId = data?.user?.id || 0;

			const refId = referralTokenService.verify(data?.startParam || '');

			dispatch(
				initialize({
					tgId: userId.toString(),
					username: data?.user?.username,
					refId,
				})
			);

			const tp = initThemeParams()[0];
			bindThemeParamsCSSVars(tp);
			const isDark = tp.isDark;
			document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');

			const miniApp = initMiniApp()[0];
			miniApp.setHeaderColor('secondary_bg_color');
			miniApp.setBgColor(tp.secondaryBgColor || '#000');
		} catch {}
	}, [dispatch]);

	useEffect(() => {
		let t: NodeJS.Timeout | undefined = undefined;
		if (notice)
			t = setTimeout(() => {
				dispatch(setNotice(null));
			}, 4000);

		return () => {
			clearTimeout(t);
		};
	}, [notice, dispatch]);

	useEffect(() => {
		if (user.role === 'MAIN') {
			eruda.init();
		}
	}, [user]);

	if (!isAppInitialized) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Loader />
			</div>
		);
	}

	return (
		<div
			style={{
				padding: '24px 16px',
				margin: '0 auto',
				overflow: 'hidden',
			}}>
			<Suspense fallback={<Loader />}>
				<RouterProvider router={router} />
				{notice && (
					<div
						onClick={() => dispatch(setNotice(null))}
						style={{
							position: 'fixed',
							bottom: '20px',
							left: '16px',
							right: '16px',
							display: 'flex',
							gap: 8,
							alignItems: 'center',
							borderRadius: 10,
							padding: '10px 12px',
							background: notice.type === 'error' ? '#e01b1b' : 'var(--green-color)',
							fontSize: 14,
						}}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M12 7.75V13"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M21.08 8.58003V15.42C21.08 16.54 20.48 17.58 19.51 18.15L13.57 21.58C12.6 22.14 11.4 22.14 10.42 21.58L4.47998 18.15C3.50998 17.59 2.90997 16.55 2.90997 15.42V8.58003C2.90997 7.46003 3.50998 6.41999 4.47998 5.84999L10.42 2.42C11.39 1.86 12.59 1.86 13.57 2.42L19.51 5.84999C20.48 6.41999 21.08 7.45003 21.08 8.58003Z"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M12 16.2V16.2999"
								stroke="white"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						{notice.type === 'error'
							? 'Something went wrong, try again later'
							: notice.message}
					</div>
				)}
				<DevPopup show={inDev} onClose={() => dispatch(toggleInDev())} />
			</Suspense>
		</div>
	);
};

export default App;
