import { createBrowserRouter } from 'react-router-dom';

import {
	Admin,
	AdminLayout,
	Holdings,
	Main,
	Payment,
	Strategy,
	AdminStrategies,
	AdminUsers,
	Send,
	AdminOutputRequests,
	AdminUser,
	Transactions,
	AdminSettings,
	AdminWallets,
	Referrals,
} from 'pages';
import AdminAdmins from 'pages/admin/AdminAdmins/AdminAdmins';

export const router = createBrowserRouter([
	{
		path: '/',
		children: [
			{
				path: '/admin',
				element: <AdminLayout />,
				children: [
					{
						path: '/admin',
						element: <Admin />,
					},
					{
						path: '/admin/strategies',
						element: <AdminStrategies />,
					},
					{
						path: '/admin/users',
						element: <AdminUsers />,
					},
					{
						path: '/admin/outputs',
						element: <AdminOutputRequests />,
					},
					{
						path: '/admin/user/:id',
						element: <AdminUser />,
					},
					{
						path: '/admin/settings',
						element: <AdminSettings />,
					},
					{
						path: '/admin/wallets',
						element: <AdminWallets />,
					},
					{
						path: '/admin/admins',
						element: <AdminAdmins />,
					},
				],
			},
			{
				path: '/',
				element: <Main />,
			},
			{
				path: '/payment',
				element: <Payment />,
			},
			{
				path: '/strategy/:id',
				element: <Strategy />,
			},
			{
				path: '/holdings',
				element: <Holdings />,
			},
			{
				path: '/send',
				element: <Send />,
			},
			{
				path: '/transactions',
				element: <Transactions />,
			},
			{
				path: '/referrals',
				element: <Referrals />,
			},
		],
	},
]);
