import { SVGProps } from 'react';

const InfoIcon = ({
	color = 'default',
	...props
}: SVGProps<SVGSVGElement> & { color?: 'default' | 'grey' }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		viewBox="0 0 24 24"
		{...props}>
		<path
			stroke={color === 'grey' ? 'var(--text-color)' : '#007AFF'}
			strokeOpacity={color === 'grey' ? 0.3 : 1}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
		/>
		<path
			stroke={color === 'grey' ? 'var(--text-color)' : '#007AFF'}
			strokeOpacity={color === 'grey' ? 0.3 : 1}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M12 13.5v-.28c0-.907.57-1.387 1.139-1.773.555-.374 1.111-.854 1.111-1.734C14.25 8.487 13.247 7.5 12 7.5s-2.25.987-2.25 2.213M11.993 16.5h.014"
		/>
	</svg>
);
export default InfoIcon;
